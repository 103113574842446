<template>
  <div class="chat-container">
    <div class="chat-header">
      <p>{{ category.name }}</p>
      <div class="chat-recipient">
        <p v-for="(item, idx) in recipients" :key="item.id">
          {{ idx < recipients - 1 ? `${item.name}, ` : ` ${item.name}` }}
        </p>
      </div>
    </div>
    <div class="chat-content">
      <div
        v-for="item in messages"
        :key="item.id"
        :class="
          `chat-message ${
            store.state.auth.user.id === item.userId
              ? 'chat-message-send'
              : 'chat-message-inbox'
          }`
        "
      >
        <p class="chat-message-date">
          {{ dates.dateToString(item.createdAt) }}
        </p>
        <p>{{ item.message }}</p>

        <p v-if="item.documents.length" class="chat-message-document-title">
          Documentos
        </p>
        <ul>
          <li
            v-for="doc in item.documents"
            :key="doc.name"
            @click="onDownload(doc)"
            class="chat-message-document-item"
          >
            {{ doc.name }}{{ doc.ext }}
          </li>
        </ul>
      </div>
    </div>
    <div class="chat-send">
      <i @click="onUpload" class="pi pi-plus"></i>
      <InputText v-model="message.text" type="text" class="chat-input" />
      <i @click="onSendMessage" class="pi pi-send"></i>
    </div>

    <BasicModal v-model:visible="message.showModal">
      <template v-slot:dialog>
        <BasicTitle title="Subir Archivos" />
        <FormError />
        <FormError />
        <div class="container-form">
          <BorderFile
            :v-model="message.selectedDocuments"
            @select="onSelectFiles"
          />
          <FormError />
          <FormError />
          <PrimaryButton label="Agregar" :click="onUpload" />
        </div>
      </template>
    </BasicModal>
  </div>
</template>

<script>
import { reactive } from "vue";
import store from "../../store";

import { dates, aws } from "../../constants";
import BorderFile from "../../widgets/file/BorderFile.vue";
import BasicTitle from "../../widgets/title/BasicTitle";
import BasicModal from "../../widgets/modal/BasicModal.vue";
import FormError from "../../widgets/error/FormError.vue";
import PrimaryButton from "../../widgets/button/PrimaryButton.vue";

export default {
  components: {
    BorderFile,
    BasicTitle,
    BasicModal,
    FormError,
    PrimaryButton,
  },
  props: {
    category: {
      type: Object,
      default: { id: -1, name: "" },
    },
    recipients: {
      type: Array,
      default: [],
    },
    messages: {
      type: Array,
      default: [],
    },
    onSend: {
      type: Function,
      default: (request) => alert(request.text),
    },
  },
  setup(props) {
    const message = reactive({
      showModal: false,
      text: "",
      selectedDocuments: [],
    });

    const onDownload = async (doc) => {
      store.commit("setLoading", true);
      const downloaded = () => {
        store.commit("setLoading", false);
      };
      aws.downloadFile(`${doc.name}${doc.ext}`, doc.link, downloaded);
    };

    const validate = () => {
      let valid = true;
      if (!message.text) valid = false;

      return valid;
    };

    const onSelectFiles = (ev) => {
      message.selectedDocuments = ev.files;
    };

    const onUpload = () => {
      message.showModal = !message.showModal;
    };

    const onSendMessage = () => {
      if (!validate()) {
        store.state.toast.add({
          severity: "info",
          summary: "",
          detail: "Debes escribir un mensaje",
          life: 5000,
        });
        return;
      }
      const request = {
        text: message.text,
        userId: store.state.auth.user.id,
        recipients: props.recipients,
        category: props.category,
        selectedFiles: message.selectedDocuments,
      };
      props.onSend(request);
      message.text = "";
      message.selectedDocuments = [];
    };

    return {
      store,
      message,
      onSendMessage,
      onSelectFiles,
      onDownload,
      onUpload,
      dates,
    };
  },
};
</script>

<style scoped>
.chat-container {
  margin: 0 4rem;
  width: calc(100vw - 8rem);
  height: 40rem;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.chat-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 0;
  background-color: var(--primary);
  color: white;
}
.chat-header > p {
  margin: 0 1rem;
}
.chat-recipient {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  margin: 0 1rem;
}
.chat-content {
  height: 100%;
  padding: 1rem;
  overflow-y: scroll;
  font-family: "klavika";
  display: flex;
  flex-direction: column;
}
.chat-message {
  width: 95%;
  padding: 1rem;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  margin-bottom: 1rem;
  position: relative;
}
.chat-message > p {
  color: var(--primary-dark);
}
.chat-message-date {
  text-align: right;
  font-size: 0.8rem;
}
.chat-message-document-title {
  margin: 1rem 0;
  font-size: 0.8rem;
}
.chat-message-document-item {
  margin-bottom: 0.5rem;
  font-size: 0.8rem;
  cursor: pointer;
  color: var(--primary-dark);
}
.chat-message-document-item:hover {
  text-decoration: underline;
}
.chat-message-send {
  background-color: var(--primary-light);
  align-self: flex-end;
}
.chat-message-send::after {
  content: "";
  width: 1rem;
  height: 1rem;
  transform: rotate(45deg);
  position: absolute;
  right: -0.5rem;
  bottom: 1.5rem;
  background-color: var(--primary-light);
}
.chat-message-inbox {
  background-color: #eeeeee;
  align-self: flex-start;
}
.chat-message-inbox::after {
  content: "";
  width: 1rem;
  height: 1rem;
  transform: rotate(45deg);
  position: absolute;
  left: -0.5rem;
  bottom: 1.5rem;
  background-color: #eeeeee;
}
.chat-send {
  background-color: var(--primary-light);
  padding: 1rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.chat-send > i {
  margin: 0 1rem;
  color: var(--primary);
  cursor: pointer;
}
.chat-send > i:hover {
  color: var(--primary-dark);
}
.chat-input {
  width: 100%;
  font-family: "klavika";
  box-shadow: none !important;
  border: none !important;
}
.container-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 1439px) {
  .chat-container {
    margin: 0 2rem;
    width: calc(100vw - 4rem);
  }
}
</style>
