<template>
  <div class="container">
    <ChatContainer
      :recipients="chat.recipients"
      :category="chat.category"
      :onSend="onSendMessage"
      :messages="messages"
    />
  </div>
</template>

<script>
import store from "../../store";
import { onBeforeMount, onBeforeUnmount, reactive, watchEffect } from "vue";
import { useRouter, useRoute } from "vue-router";
import { openMode } from "../../constants";
import { validation, actions, aws } from "../../constants";

import ChatContainer from "../../widgets/chat/ChatContainer.vue";

export default {
  components: {
    ChatContainer,
  },
  computed: {
    messages() {
      return store.state.message.messages;
    },
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const chat = reactive({
      recipients: [],
      category: null,
    });

    const onSendMessage = async (data) => {
      store.commit("setLoading", true);

      const responsePath = await store.dispatch(
        actions.documentActions.getPath,
        {
          buildingId: store.state.general.selectedBuilding,
          folderName: `${store.state.auth.user.name} (${store.state.auth.user.email})`,
          documentTypeName: "Mensajes",
        }
      );
      if (responsePath.ok) {
        let count = 0;
        const documents = [];
        const { length } = data.selectedFiles;
        const finish = async () => {
          const isSend = store.state.message.isSend;
          const selected = isSend
            ? store.state.message.selectedSend[0]
            : store.state.message.selectedInbox[0];

          const request = {
            buildingId: store.state.general.selectedBuilding,
            userId: data.userId,
            recipients: data.recipients,
            message: data.text,
            messageTypeId: chat.category.id,
            messageParentId: selected.message.messageParentId,
            messageStatusId: 2,
            documents,
          };

          const response = await store.dispatch(
            actions.messageActions.create,
            request
          );
          if (response.ok) {
            chat.category = {
              id: selected.message.type.id,
              name: selected.message.type.name,
            };
            chat.recipients = isSend
              ? [{ id: selected.userId, name: selected.name }]
              : [
                  {
                    id: selected.message.user.id,
                    name: selected.message.user.name,
                  },
                ];

            await store.dispatch(actions.messageActions.detail, {
              buildingId: selected.message.buildingId,
              messageId: response.messageId,
              userId: selected.message.userId,
              recipientId: selected.userId,
            });
            store.commit("setLoading", false);
          } else {
            store.commit("setLoading", false);
            alert(response.message);
          }
        };

        if (count === length) finish();

        data.selectedFiles.forEach(async (doc) => {
          const name = doc.name;
          const key = `${responsePath.path}${name}`;
          const uploaded = async () => {
            documents.push({
              path: responsePath.path,
              name: name
                .split(".")
                .slice(0, -1)
                .join("."),
              ext: validation.extensionRegex.exec(name)[0],
            });

            count += 1;
            if (count === length) finish();
          };
          aws.uploadFile(key, doc, uploaded);
        });
      } else {
        store.state.toast.add({
          severity: "error",
          summary: "",
          detail: responsePath.message,
          life: 5000,
        });
      }
    };

    onBeforeMount(async () => {
      store.commit("setLoading", true);
      store.commit("addBreadcrumb", { label: route.name, to: route.path });
      const isSend = store.state.message.isSend;
      const selected = isSend
        ? store.state.message.selectedSend[0]
        : store.state.message.selectedInbox[0];

      chat.category = {
        id: selected.message.type.id,
        name: selected.message.type.name,
      };
      chat.recipients = isSend
        ? [{ id: selected.userId, name: selected.name }]
        : [{ id: selected.message.user.id, name: selected.message.user.name }];

      await store.dispatch(actions.messageActions.detail, {
        buildingId: selected.message.buildingId,
        messageId: selected.message.id,
        userId: selected.message.user.id,
        recipientId: selected.message.recipients[0].userId,
      });

      store.commit("setLoading", false);
    });

    onBeforeUnmount(() => {
      store.commit("removeBreadcrumb");
    });

    return { store, chat, onSendMessage };
  },
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
